import AppTypes from '../action-types/app';
import WebClient from '../utils/web-client';

export const ResetAPIStatus = () => ({
    type: AppTypes.API_RESET,
});

export const APIError = (text = null, autoHideDisabled = null) => ({
    type: AppTypes.API_ERROR,
    payload: {
        text,
        autoHideDisabled,
    },
});

export const APISuccess = (text = null, autoHideDisabled = null) => ({
    type: AppTypes.API_SUCCESS,
    payload: {
        text,
        autoHideDisabled,
    },
});

export const GetAppConfigBegin = () => ({
    type: AppTypes.GET_APP_CONFIG_BEGIN,
});
const GetAppConfigSuccess = (payload) => ({
    type: AppTypes.GET_APP_CONFIG_SUCCESS,
    payload,
});
const GetAppConfigError = (errorMsg) => ({
    type: AppTypes.GET_APP_CONFIG_ERROR,
    payload: { errorMsg },
});

export const GetAppConfig = () => async (dispatch) => {
    dispatch(GetAppConfigBegin());
    try {
        const { data } = await WebClient.get('/global-config');
        dispatch(GetAppConfigSuccess(data));
    } catch (error) {
        dispatch(GetAppConfigError(error));
    }
};

const SaveAdminContentBegin = () => ({
    type: AppTypes.SAVE_ADMIN_CONTENT_BEGIN,
});
const SaveAdminContentSuccess = (payload) => ({
    type: AppTypes.SAVE_ADMIN_CONTENT_SUCCESS,
    payload,
});
const SaveAdminContentError = () => ({
    type: AppTypes.SAVE_PRIVACY_POLICY_ERROR,
});

export const SaveAdminContent = (content, type, onSuccess) => async (dispatch) => {
    dispatch(SaveAdminContentBegin());
    try {
        const { data } = await WebClient.patch(`/global-config/${type}`, { content });
        dispatch(APISuccess('Content successfully edited.'));
        dispatch(SaveAdminContentSuccess({ data, type }));
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(SaveAdminContentError());
        dispatch(APIError('Error editing content.'));
    }
};
