import React from 'react';
import PropTypes from 'prop-types';
import isRequiredIf from 'react-proptype-conditional-require';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Colors from '../../styles/colors';

const ProgressSpinner = ({
    id,
    isLoading,
    ariaLabel,
    ariaLabelledBy,
    valueText,
}) => (
    <Backdrop sx={{ zIndex: 99999 }} open={isLoading}>
        <CircularProgress
            id={id}
            sx={{ color: Colors.white }}
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledBy}
            aria-valuetext={valueText}
        />
    </Backdrop>
);

ProgressSpinner.defaultProps = {
    ariaLabel: null,
    ariaLabelledBy: null,
    valueText: null,
};

ProgressSpinner.propTypes = {
    id: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    // one of, either ariaLabel or ariaLabelledby, is required
    ariaLabel: isRequiredIf(PropTypes.string, (props) => !props.ariaLabelledBy),
    ariaLabelledBy: isRequiredIf(PropTypes.string, (props) => !props.ariaLabel),
    valueText: PropTypes.string,
};

export default ProgressSpinner;
