import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GA4React from 'ga-4-react';
import * as Sentry from '@sentry/react';

let ga4React = null;

export const useGA4 = () => {
    if (ga4React) {
        return ga4React;
    }
    if (process.env.REACT_APP_GA4) {
        ga4React = new GA4React(process.env.REACT_APP_GA4);
        ga4React.initialize().then(() => {
            ga4React.isInitialized = true;
            return ga4React;
        }, (err) => {
            Sentry.captureException(err);
        });
    }

    return null;
};

const GoogleAnalytics = () => {
    const ga = useGA4();
    const location = useLocation();

    useEffect(() => {
        if (ga && ga.isInitialized) {
            ga.pageview(location.pathname, location.search, location.pathname);
        }
    }, [location]);

    return null;
};

export default GoogleAnalytics;

const sendEvent = (category, action) => {
    try {
        ga4React.gtag('event', `${category}-${action}`);
    } catch (error) {
        Sentry.captureException(error);
    }
};

export const DownloadClick = (type) => {
    sendEvent('Download', type);
};
