import WebClient from '../utils/web-client';
import ResourcesTypes from '../action-types/resources';
import { APIError, APISuccess } from './app';
import {
    GetMatterFull,
} from './matters';
import { getPaginatedResourcesParams } from '../selectors/resources';
import prepareContentType from '../utils/prepare-content-type';

const GetResourceBegin = () => ({
    type: ResourcesTypes.GET_RESOURCE_BEGIN,
});
const GetResourceSuccess = (payload) => ({
    type: ResourcesTypes.GET_RESOURCE_SUCCESS,
    payload,
});
const GetResourceError = () => ({
    type: ResourcesTypes.GET_RESOURCE_ERROR,
});

export const GetResource = (id) => async (dispatch) => {
    dispatch(GetResourceBegin());

    try {
        const { data } = await WebClient.get(`/resources/${id}`);
        dispatch(GetResourceSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetResourceError());
    }
};

export const UpdatePaginatedResourcesParams = (payload) => ({
    type: ResourcesTypes.UPDATE_PAGINATED_RESOURCES_PARAMS,
    payload,
});

const GetPaginatedResourcesBegin = () => ({
    type: ResourcesTypes.GET_PAGINATED_RESOURCES_BEGIN,
});
const GetPaginatedResourcesSuccess = (payload) => ({
    type: ResourcesTypes.GET_PAGINATED_RESOURCES_SUCCESS,
    payload,
});
const GetPaginatedResourcesError = () => ({
    type: ResourcesTypes.GET_PAGINATED_RESOURCES_ERROR,
});

export const GetPaginatedResources = () => async (dispatch, getState) => {
    dispatch(GetPaginatedResourcesBegin());

    const paginatedResourcesParams = getPaginatedResourcesParams(getState());
    const payload = { ...paginatedResourcesParams };
    delete payload.page;
    delete payload.pageSize;
    delete payload.results;
    delete payload.total;

    try {
        const { data } = await WebClient.post(`/resources/${paginatedResourcesParams.page}/${paginatedResourcesParams.pageSize}`, payload);
        dispatch(GetPaginatedResourcesSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetPaginatedResourcesError());
    }
};

const AddResourceBegin = () => ({
    type: ResourcesTypes.ADD_RESOURCE_BEGIN,
});
const AddResourcesuccess = () => ({
    type: ResourcesTypes.ADD_RESOURCE_SUCCESS,
});
const AddResourceError = () => ({
    type: ResourcesTypes.ADD_RESOURCE_ERROR,
});

export const AddResource = (data, onSuccess) => async (dispatch) => {
    dispatch(AddResourceBegin());

    const payload = { ...data };

    try {
        await WebClient.post('/resources', payload);
        dispatch(AddResourcesuccess());
        dispatch(APISuccess('Resource successfully created.'));
        dispatch(GetPaginatedResources()); // assumes we are on the site management / resources page
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError('Error creating new resource.'));
        dispatch(AddResourceError());
    }
};

const EditResourceBegin = () => ({
    type: ResourcesTypes.EDIT_RESOURCE_BEGIN,
});
const EditResourceSuccess = () => ({
    type: ResourcesTypes.EDIT_RESOURCE_SUCCESS,
});
const EditResourceError = () => ({
    type: ResourcesTypes.EDIT_RESOURCE_ERROR,
});

export const EditResource = (resourceId, data, onSuccess) => async (dispatch) => {
    dispatch(EditResourceBegin());

    try {
        await WebClient.patch(`/resources/${resourceId}`, data);
        dispatch(EditResourceSuccess());
        dispatch(APISuccess('Resource successfully edited.'));
        dispatch(GetPaginatedResources());
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError('Error editing resource.'));
        dispatch(EditResourceError());
    }
};

const DeleteResourceBegin = () => ({
    type: ResourcesTypes.DELETE_RESOURCE_BEGIN,
});
const DeleteResourceSuccess = () => ({
    type: ResourcesTypes.DELETE_RESOURCE_SUCCESS,
});
const DeleteResourceError = () => ({
    type: ResourcesTypes.DELETE_RESOURCE_ERROR,
});

export const DeleteResource = (resourceId, onSuccess, matterId) => async (dispatch) => {
    dispatch(DeleteResourceBegin());

    try {
        await WebClient.delete(`/resources/${resourceId}`);
        dispatch(DeleteResourceSuccess());
        dispatch(APISuccess('Resource successfully deleted.'));
        if (matterId) dispatch(GetMatterFull(matterId)); // used to update a matter's presentation when editing a user inline
        else if (!matterId) dispatch(GetPaginatedResources()); // assumes we are on the site management / resources page
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError('Error deleting resource.'));
        dispatch(DeleteResourceError());
    }
};

const UploadResourceBegin = (payload) => ({
    type: ResourcesTypes.UPLOAD_BEGIN,
    payload,
});
const UploadResourceSuccess = (payload) => ({
    type: ResourcesTypes.UPLOAD_SUCCESS,
    payload,
});
const UploadResourceError = (payload) => ({
    type: ResourcesTypes.UPLOAD_ERROR,
    payload,
});

export const UploadResource = (file, matterId, onSuccess, onError, listIndex) => async (dispatch) => {
    const payload = {
        name: file.name,
        uploadErrMsg: null,
        status: 'inprogress',
        listIndex,
    };
    // this will be caught by reducers/matters and process data from there
    dispatch(UploadResourceBegin(payload));

    const [formData, config] = prepareContentType('form-data', { file });
    if (matterId) formData.matterId = matterId;
    try {
        const uploadFile = await WebClient.post('/resource-file', formData, config);
        const payloadSuccess = {
            listIndex,
            id: uploadFile.data.id,
            name: file.name,
            title: file.name,
            status: 'success',
        };
        dispatch(UploadResourceSuccess(payloadSuccess));
    } catch (error) {
        const payloadError = {
            listIndex,
            id: Math.floor(Math.random() * (1000 - 1 + 1) + 1),
            name: file.name,
            title: file.name,
            status: 'error',
            errMsg: error.message,
            // uploadStatus: 'error',
            uploadErrMsg: 'Failed',
        };
        dispatch(UploadResourceError(payloadError));
    }
};
