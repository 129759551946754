import UserTypes from '../action-types/user';
import AuthTypes from '../action-types/auth';

const internals = {
    initial: () => ({
        user: {},
        updateUserStatus: {
            error: false,
            completed: false,
        },
    }),
};

const UserReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case UserTypes.SETUP_USER:
    case UserTypes.GET_USER_SUCCESS:
        return {
            ...state,
            user: {
                ...payload,
            },
        };

    // clear all stored data on logout
    case AuthTypes.LOGOUT:
        return {
            ...internals.initial(),
        };
    case UserTypes.UPDATE_USER_BEGIN:
        return {
            ...state,
            updateUserStatus: {
                error: false,
                completed: false,
            },
        };
    case UserTypes.UPDATE_USER_ERROR:
        return {
            ...state,
            updateUserStatus: {
                error: true,
                completed: true,
            },
        };
    case UserTypes.UPDATE_USER_SUCCESS:
        return {
            ...state,
            updateUserStatus: {
                error: false,
                completed: true,
            },
        };

    default:
        // do nothing
    }
    return state;
};

export default UserReducer;
