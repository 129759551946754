import WebClient from '../utils/web-client';
import FormsTypes from '../action-types/forms';
import { APIError, APISuccess } from './app';
import { GetMatterFull } from './matters';
import { getPaginatedFormsParams } from '../selectors/forms';
import { getViewMatter } from '../selectors/matters';
import form from '../utils/form-preprocessor';

const EditFormNotesBegin = () => ({
    type: FormsTypes.EDIT_FORM_NOTES_BEGIN,
});
const EditFormNotesSuccess = () => ({
    type: FormsTypes.EDIT_FORM_NOTES_SUCCESS,
});
const EditFormNotesError = () => ({
    type: FormsTypes.EDIT_FORM_NOTES_ERROR,
});

export const EditFormNotes = (matterId, id, notes) => async (dispatch) => {
    dispatch(EditFormNotesBegin());

    try {
        await WebClient.patch(`/filled-forms/${id}`, { notes });
        dispatch(EditFormNotesSuccess());
        dispatch(APISuccess('Form notes updated.'));
        dispatch(GetMatterFull(matterId));
    } catch (error) {
        dispatch(APIError('Error updating form notes.'));
        dispatch(EditFormNotesError());
    }
};

const FinalizeFormBegin = () => ({
    type: FormsTypes.FINALIZE_FORM_BEGIN,
});
const FinalizeFormSuccess = () => ({
    type: FormsTypes.FINALIZE_FORM_SUCCESS,
});
const FinalizeFormError = () => ({
    type: FormsTypes.FINALIZE_FORM_ERROR,
});

export const FinalizeForm = (matterId, formId, status) => async (dispatch) => {
    dispatch(FinalizeFormBegin());

    try {
        await WebClient.patch(`/filled-forms/${formId}`, { finalized: status });
        dispatch(FinalizeFormSuccess());
        dispatch(APISuccess(status ? 'Form finalized.' : 'Form no longer finalized.'));
        dispatch(GetMatterFull(matterId));
    } catch (error) {
        dispatch(APIError('Error marking form finalized.'));
        dispatch(FinalizeFormError());
    }
};

const UploadFilledFormBegin = () => ({
    type: FormsTypes.UPLOAD_FILLED_FORM_BEGIN,
});
const UploadFilledFormSuccess = () => ({
    type: FormsTypes.UPLOAD_FILLED_FORM_SUCCESS,
});
const UploadFilledFormError = () => ({
    type: FormsTypes.UPLOAD_FILLED_FORM_ERROR,
});

export const UploadFilledForm = (data, onSuccess) => async (dispatch, getState) => {
    dispatch(UploadFilledFormBegin());

    const currentMatter = getViewMatter(getState());

    const payload = { ...data };
    if (payload.notes === '') delete payload.notes;
    const config = {
        headers: { 'content-type': 'multipart/form-data' },
    };
    try {
        await WebClient.post('/filled-forms', form(payload), config);
        // need to refresh the matter underneath the form that is being modified
        // this is where the form data is being propegated from under some conditions
        if (currentMatter && currentMatter.id) {
            dispatch(GetMatterFull(currentMatter.id));
        }
        dispatch(APISuccess('Form uploaded successfully.'));
        dispatch(UploadFilledFormSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError('Error uploading form.'));
        dispatch(UploadFilledFormError());
    }
};

export const ResetGetForm = () => ({
    type: FormsTypes.GET_FORM_RESET,
});
const GetFormBegin = () => ({
    type: FormsTypes.GET_FORM_BEGIN,
});
const GetFormSuccess = (payload) => ({
    type: FormsTypes.GET_FORM_SUCCESS,
    payload,
});
const GetFormError = () => ({
    type: FormsTypes.GET_FORM_ERROR,
});

export const GetForm = (id) => async (dispatch) => {
    dispatch(GetFormBegin());

    try {
        const { data } = await WebClient.get(`/forms/${id}`);

        dispatch(GetFormSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetFormError());
    }
};

export const UpdatePaginatedFormsParams = (payload) => ({
    type: FormsTypes.UPDATE_PAGINATED_FORMS_PARAMS,
    payload,
});

const GetPaginatedFormsBegin = () => ({
    type: FormsTypes.GET_PAGINATED_FORMS_BEGIN,
});
const GetPaginatedFormsSuccess = (payload) => ({
    type: FormsTypes.GET_PAGINATED_FORMS_SUCCESS,
    payload,
});
const GetPaginatedFormsError = () => ({
    type: FormsTypes.GET_PAGINATED_FORMS_ERROR,
});

export const GetPaginatedForms = () => async (dispatch, getState) => {
    dispatch(GetPaginatedFormsBegin());

    const paginatedFormsParams = getPaginatedFormsParams(getState());
    const payload = { ...paginatedFormsParams };
    delete payload.page;
    delete payload.pageSize;
    delete payload.results;
    delete payload.total;

    try {
        const { data } = await WebClient.post(`/forms/${paginatedFormsParams.page}/${paginatedFormsParams.pageSize}`, payload);
        dispatch(GetPaginatedFormsSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetPaginatedFormsError());
    }
};

const AddFormBegin = () => ({
    type: FormsTypes.ADD_FORM_BEGIN,
});
const AddFormSuccess = () => ({
    type: FormsTypes.ADD_FORM_SUCCESS,
});
const AddFormError = () => ({
    type: FormsTypes.ADD_FORM_ERROR,
});

export const AddForm = (data, onSuccess) => async (dispatch) => {
    dispatch(AddFormBegin());

    const payload = { ...data };
    const config = {
        headers: { 'content-type': 'multipart/form-data' },
    };

    try {
        await WebClient.post('/forms', form(payload), config);
        dispatch(AddFormSuccess());
        dispatch(GetPaginatedForms()); // assumes we are on the site management / forms page
        dispatch(APISuccess('New form successfully created.'));
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError('Error creating new form.'));
        dispatch(AddFormError());
    }
};

const EditFormBegin = () => ({
    type: FormsTypes.EDIT_FORM_BEGIN,
});
const EditFormSuccess = () => ({
    type: FormsTypes.EDIT_FORM_SUCCESS,
});
const EditFormError = () => ({
    type: FormsTypes.EDIT_FORM_ERROR,
});

export const EditForm = (formId, data, onSuccess) => async (dispatch) => {
    dispatch(EditFormBegin());

    const payload = { ...data };
    const config = {
        headers: { 'content-type': 'multipart/form-data' },
    };

    try {
        await WebClient.patch(`/forms/${formId}`, form(payload), config);
        dispatch(EditFormSuccess());
        dispatch(GetPaginatedForms()); // assumes we are on the site management / forms page
        dispatch(APISuccess('Form successfully edited.'));
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError('Error editing form.'));
        dispatch(EditFormError());
    }
};

const DeleteFormBegin = () => ({
    type: FormsTypes.DELETE_FORM_BEGIN,
});
const DeleteFormsuccess = () => ({
    type: FormsTypes.DELETE_FORM_SUCCESS,
});
const DeleteFormError = () => ({
    type: FormsTypes.DELETE_FORM_ERROR,
});

export const DeleteForm = (formId, onSuccess, matterId) => async (dispatch) => {
    dispatch(DeleteFormBegin());

    try {
        await WebClient.delete(`/forms/${formId}`);
        dispatch(DeleteFormsuccess());
        dispatch(APISuccess('Form successfully deleted.'));
        if (matterId) dispatch(GetMatterFull(matterId)); // used to update a matter's presentation when editing a user inline
        else if (!matterId) dispatch(GetPaginatedForms()); // assumes we are on the site management / forms page
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError('Error deleting form.'));
        dispatch(DeleteFormError());
    }
};
