import { combineReducers } from 'redux';
import Auth from './auth';
import User from './user';
import Matters from './matters';
import App from './app';
import Users from './users';
import Orgs from './orgs';
import Resources from './resources';
import Forms from './forms';
import Partner from './partner';
import I18n from './i18n';

const Reducers = () => combineReducers({
    i18n: I18n,
    auth: Auth,
    user: User, // the authenticated user
    matters: Matters,
    app: App,
    users: Users, // the applications users
    orgs: Orgs,
    resources: Resources,
    forms: Forms,
    partner: Partner,
});

export default Reducers;
