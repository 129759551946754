import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const getResourcesState = (state) => state.resources;

export const getViewResource = createSelector(
    getResourcesState,
    (resourceState) => resourceState.viewResource,
);

// create a "selector creator" that uses lodash.isequal instead of ===
const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    isEqual,
);

export const getPaginatedResources = createSelector(
    getResourcesState,
    (resourcesState) => resourcesState.paginatedResources.results,
);

// used to present 'getPaginatedMatterParams' with a cleaned state to compare
const cleanPaginatedResourcesParams = createSelector(
    getResourcesState,
    (resourcesState) => {
        const cleanedParams = { ...resourcesState.paginatedResources };
        delete cleanedParams.results;
        delete cleanedParams.total;
        return cleanedParams;
    },
);

// uses lodash.isequal to compare state of the object
export const getPaginatedResourcesParams = createDeepEqualSelector(
    cleanPaginatedResourcesParams,
    (cleanedResourcesState) => cleanedResourcesState,
);

export const getTotalResourcesCount = createSelector(
    getResourcesState,
    (resourcesState) => resourcesState.paginatedResources.total,
);
