const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    ADD_FORM_BEGIN: true,
    ADD_FORM_SUCCESS: true,
    ADD_FORM_ERROR: true,
    EDIT_FORM_BEGIN: true,
    EDIT_FORM_SUCCESS: true,
    EDIT_FORM_ERROR: true,
    DELETE_FORM_BEGIN: true,
    DELETE_FORM_SUCCESS: true,
    DELETE_FORM_ERROR: true,
    UPDATE_PAGINATED_FORMS_PARAMS: true,
    GET_PAGINATED_FORMS_BEGIN: true,
    GET_PAGINATED_FORMS_SUCCESS: true,
    GET_PAGINATED_FORMS_ERROR: true,
    GET_FORM_BEGIN: true,
    GET_FORM_SUCCESS: true,
    GET_FORM_ERROR: true,
    GET_FORM_RESET: true,
    UPLOAD_FILLED_FORM_BEGIN: true,
    UPLOAD_FILLED_FORM_SUCCESS: true,
    UPLOAD_FILLED_FORM_ERROR: true,
    FINALIZE_FORM_BEGIN: true,
    FINALIZE_FORM_SUCCESS: true,
    FINALIZE_FORM_ERROR: true,
    EDIT_FORM_NOTES_BEGIN: true,
    EDIT_FORM_NOTES_ERROR: true,
    EDIT_FORM_NOTES_SUCCESS: true,
});
