export default {
    // primary
    white: '#ffffff',
    lightBeige: '#F7F7F7',
    textGrey: '#29292B',
    textGreyRGB: '41, 41, 43',
    darkBlack: '#221E1F',
    darkBlackRGB: '34, 30, 31',

    // secondary
    gray: '#5C6E81',
    grayRGB: '92, 110, 129',
    goldenGray: '#ABA89E',
    goldenGrayRGB: '171, 168, 158',
    midGray: '#B3B3B3',

    // tertiary
    yellow: '#FFCE00',
    deepBlue: '#1F355E',
    green: '#73A950',
    acceptedGreen: '#9AD775',
    lightGray: '#E1E1E1',

    // mui
    error: '#A03123',
    success: '#73A950',

    filledBg: '#F6F6F6',
    selectHover: '#f4f8fc',

    // nav
    charcoal: '#65656A',
    charcoalRGB: '101, 101, 106',
    darkGray: '#5B5770',
    darkGrayRGB: '118, 118, 118',

    // Disabled Buttons
    disabledBtnBackground: '#E7E6ED',
    disabledBtnBackgroundRGB: '231, 230, 237',
};
