const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    GET_ORGANIZATIONS_BEGIN: true,
    GET_ORGANIZATIONS_SUCCESS: true,
    GET_ORGANIZATIONS_ERROR: true,
    ADD_ORG_BEGIN: true,
    ADD_ORG_SUCCESS: true,
    ADD_ORG_ERROR: true,
    EDIT_ORG_DETAILS_BEGIN: true,
    EDIT_ORG_DETAILS_SUCCESS: true,
    EDIT_ORG_DETAILS_ERROR: true,
    DELETE_ORG_BEGIN: true,
    DELETE_ORG_SUCCESS: true,
    DELETE_ORG_ERROR: true,
    UPDATE_PAGINATED_ORGS_PARAMS: true,
    GET_PAGINATED_ORGS_BEGIN: true,
    GET_PAGINATED_ORGS_SUCCESS: true,
    GET_PAGINATED_ORGS_ERROR: true,
});
