import PartnerTypes from '../action-types/partner';

const internals = {
    initial: () => ({
        firstName: '',
        email: '',
        subject: '',
        recipients: '',
        requestCode: null,
        uploadedDocumentList: [],
    }),
};

const PartnerReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case PartnerTypes.UPLOAD_DOC_BEGIN:
    case PartnerTypes.UPLOAD_DOC_SUCCESS:
    case PartnerTypes.UPLOAD_DOC_ERROR: {
        let documentList = [...state.uploadedDocumentList];

        // if the payload is empty, this is our first upload
        if (documentList.length === 0) documentList = [payload];
        else {
            // check to see if the object exists in the current documentList
            // use listIndex to guide us to the position in the array we want to edit
            const objIndex = documentList.findIndex(((obj) => obj.listIndex === payload.listIndex));
            // if it doesn't, add it to the end
            if (objIndex === -1) documentList = [...documentList, payload];
            // if it does, then replace it's data set
            else {
                // Update the object.
                documentList[objIndex] = payload;
            }
        }
        return {
            ...state,
            uploadedDocumentList: documentList,
        };
    }
    case PartnerTypes.DELETE_DOC_BEGIN: {
        const documentList = [...state.uploadedDocumentList];
        const objIndex = documentList.findIndex(((obj) => obj.listIndex === payload.listIndex));
        if (objIndex > -1) {
            documentList.splice(objIndex, 1);
        }

        return {
            ...state,
            uploadedDocumentList: documentList,
        };
    }
    case PartnerTypes.VALIDATE_REQUEST_BEGIN:
        return {
            ...state,
            requestCode: null,
            firstName: '',
            email: '',
            subject: '',
            recipients: '',
        };
    case PartnerTypes.VALIDATE_REQUEST_SUCCESS:
        return {
            ...state,
            requestCode: payload.requestCode,
            firstName: payload.firstName,
            email: payload.email,
            subject: payload.subject,
            recipients: payload.recipients,
        };
    case PartnerTypes.VALIDATE_REQUEST_ERROR:
        return {
            ...state,
            requestCode: null,
            firstName: '',
            email: '',
            subject: '',
            recipients: '',
        };

    default:
        // do nothing
    }
    return state;
};

export default PartnerReducer;
