const prepareContentType = (contentType, values) => {
    if (contentType === 'form-data') {
        const config = {
            headers: { 'content-type': 'multipart/form-data' },
        };
        const formData = new FormData();

        Object.entries(values).forEach(([key, value]) => {
            if (value && Array.isArray(value)) {
                // to add an array to FormData append the same key for each value in the array
                value.forEach((val) => {
                    if (val && typeof val === 'object' && !(val instanceof Blob)) {
                        formData.append(key, JSON.stringify(val));
                    } else {
                        formData.append(key, val);
                    }
                });
            } else if (value && typeof value === 'object' && !(value instanceof Blob)) {
                formData.append(key, JSON.stringify(value));
            } else {
                formData.append(key, value);
            }
        });

        return [formData, config];
    }

    throw Error('Unknown content-type');
};

export default prepareContentType;
