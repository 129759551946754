import FormsTypes from '../action-types/forms';

const internals = {
    initial: () => ({
        viewForm: null,
        finalizeFormStatus: {
            error: false,
            completed: false,
        },
        uploadFilledFormStatus: {
            error: false,
            completed: false,
        },
        viewFormStatus: {
            error: false,
            completed: false,
        },
        paginatedForms: {
            page: 0,
            pageSize: 10,
            sortBy: 'name',
            sortOrder: 'asc',
            results: null,
            total: null,
        },
        paginatedFormsStatus: {
            error: false,
            completed: false,
        },
        addFormStatus: {
            error: false,
            completed: false,
        },
        deleteFormStatus: {
            error: false,
            completed: false,
        },
        editFormStatus: {
            error: false,
            completed: false,
        },
    }),
};

const FormReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { type, payload } = action;

    switch (type) {
    case FormsTypes.GET_FORM_RESET:
    case FormsTypes.GET_FORM_BEGIN:
        return {
            ...state,
            viewForm: null,
            viewFormStatus: {
                error: false,
                completed: false,
            },
        };
    case FormsTypes.GET_FORM_SUCCESS:
        return {
            ...state,
            viewForm: payload,
            viewFormStatus: {
                error: false,
                completed: true,
            },
        };
    case FormsTypes.UPDATE_PAGINATED_FORMS_PARAMS: {
        return {
            ...state,
            paginatedForms: {
                ...state.paginatedForms,
                ...payload,
            },
        };
    }
    case FormsTypes.GET_PAGINATED_FORMS_BEGIN:
        return {
            ...state,
            paginatedFormsStatus: {
                error: false,
                completed: false,
            },
        };
    case FormsTypes.GET_PAGINATED_FORMS_ERROR:
        return {
            ...state,
            paginatedFormsStatus: {
                error: true,
                completed: true,
            },
        };
    case FormsTypes.GET_PAGINATED_FORMS_SUCCESS:
        return {
            ...state,
            paginatedFormsStatus: {
                error: false,
                completed: true,
            },
            paginatedForms: {
                ...state.paginatedForms,
                results: payload.results,
                total: payload.total,
            },
        };
    case FormsTypes.ADD_FORM_BEGIN:
        return {
            ...state,
            addFormStatus: {
                error: false,
                completed: false,
            },
        };
    case FormsTypes.ADD_FORM_SUCCESS:
        return {
            ...state,
            addFormStatus: {
                error: false,
                completed: true,
            },
        };
    case FormsTypes.ADD_FORM_ERROR:
        return {
            ...state,
            addFormStatus: {
                error: true,
                completed: true,
            },
        };
    case FormsTypes.DELETE_FORM_BEGIN:
        return {
            ...state,
            deleteFormStatus: {
                error: false,
                completed: false,
            },
        };
    case FormsTypes.DELETE_FORM_SUCCESS:
        return {
            ...state,
            deleteFormStatus: {
                error: false,
                completed: true,
            },
        };
    case FormsTypes.DELETE_FORM_ERROR:
        return {
            ...state,
            deleteFormStatus: {
                error: true,
                completed: true,
            },
        };
    case FormsTypes.EDIT_FORM_BEGIN:
        return {
            ...state,
            editFormStatus: {
                error: false,
                completed: false,
            },
        };
    case FormsTypes.EDIT_FORM_SUCCESS:
        return {
            ...state,
            editFormStatus: {
                error: false,
                completed: true,
            },
        };
    case FormsTypes.EDIT_FORM_ERROR:
        return {
            ...state,
            editFormStatus: {
                error: true,
                completed: true,
            },
        };
    case FormsTypes.UPLOAD_FILLED_FORM_BEGIN:
        return {
            ...state,
            uploadFilledFormStatus: {
                error: false,
                completed: false,
            },
        };
    case FormsTypes.UPLOAD_FILLED_FORM_SUCCESS:
        return {
            ...state,
            uploadFilledFormStatus: {
                error: false,
                completed: true,
            },
        };
    case FormsTypes.UPLOAD_FILLED_FORM_ERROR:
        return {
            ...state,
            uploadFilledFormStatus: {
                error: true,
                completed: true,
            },
        };
    case FormsTypes.FINALIZE_FORM_BEGIN:
        return {
            ...state,
            finalizeFormStatus: {
                error: false,
                completed: false,
            },
        };
    case FormsTypes.FINALIZE_FORM_SUCCESS:
        return {
            ...state,
            finalizeFormStatus: {
                error: false,
                completed: true,
            },
        };
    case FormsTypes.FINALIZE_FORM_ERROR:
        return {
            ...state,
            finalizeFormStatus: {
                error: true,
                completed: true,
            },
        };

    default:
        // do nothing
    }
    return state;
};

export default FormReducer;
