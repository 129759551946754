import I18n from '../action-types/i18n';

const internals = {
    initial: () => ({
        language: 'en',
    }),
};

const I18nReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case I18n.UPDATE_LANGUAGE:
        if (payload.lang !== 'es' && payload.lang !== 'en') {
            return {
                ...state,
                language: 'en',
            };
        }
        return {
            ...state,
            language: payload.lang,
        };

    default:
        // do nothing
    }
    return state;
};

export default I18nReducer;
