import WebClient from '../utils/web-client';
import AuthTypes from '../action-types/auth';
import UserTypes from '../action-types/user';
import { APIError, APISuccess } from './app';

export const ClearErrors = () => ({
    type: AuthTypes.INITIALIZE_APP,
});

export const LoginBegin = () => ({
    type: AuthTypes.LOGIN_BEGIN,
});
const LoginSuccess = () => ({
    type: AuthTypes.LOGIN_SUCCESS,
});
const LoginError = (errorMsg) => ({
    type: AuthTypes.LOGIN_ERROR,
    payload: { errorMsg },
});
export const SetupUser = (user) => ({
    type: UserTypes.SETUP_USER,
    payload: user,
});

export const LoginConfirmationBegin = () => ({
    type: AuthTypes.LOGIN_CONFIRMATION_BEGIN,
});
const LoginConfirmationSuccess = (token, role) => ({
    type: AuthTypes.LOGIN_CONFIRMATION_SUCCESS,
    payload: { token, role },
});
const LoginConfirmationError = (errorMsg) => ({
    type: AuthTypes.LOGIN_CONFIRMATION_ERROR,
    payload: { errorMsg },
});

export const LoginConfirmation = (proofToken, code, onSuccess, onError) => async (dispatch) => {
    dispatch(LoginConfirmationBegin());
    try {
        const { data } = await WebClient.post('/users/code', { proofToken, code });

        // update auth token
        WebClient.updateAuth(data.token);

        dispatch(SetupUser(data.user));
        dispatch(LoginConfirmationSuccess(data.token, data.user.role));
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorType = 'UNKNOWN';
        if (error.response && (error.response.status === 401)) {
            errorType = 'UNAUTHORIZED';
        }
        if (error.response && (error.response.status === 422)) {
            errorType = 'UNPROCESSABLE';
        }
        dispatch(LoginConfirmationError(errorType));
        if (onError) onError(errorType);
    }
};

export const Login = (formValues, onSuccess, onError) => async (dispatch) => {
    const { email, password } = formValues;

    dispatch(LoginBegin());
    try {
        const { data } = await WebClient.post(
            '/login',
            { email, password },
            { responseType: 'text' },
        );

        dispatch(LoginSuccess());
        if (!data.proofToken) { // Internal users can skip 2FA
            dispatch(SetupUser(data.user));
            dispatch(LoginConfirmationSuccess(data.token, data.user.role));
        }
        // proofToken is accepted by the onsuccess function, but is optional
        // we want the success handler to be called either way, even if data.proofToken is null
        if (onSuccess) onSuccess(data.proofToken);
    } catch (error) {
        let errorType = 'UNKNOWN';
        if (error.response && (error.response.status === 401)) {
            errorType = 'UNAUTHORIZED';
        }
        if (error.response && (error.response.status === 422)) {
            errorType = 'UNPROCESSABLE';
        }
        dispatch(LoginError(errorType));
        if (onError) onError(errorType);
    }
};

export const Logout = () => ({
    type: AuthTypes.LOGOUT,
});

export const ForgotPassBegin = () => ({
    type: AuthTypes.FORGOT_PASSWORD_BEGIN,
});
const ForgotPassSuccess = () => ({
    type: AuthTypes.FORGOT_PASSWORD_SUCCESS,
});
const ForgotPassError = (errorMsg) => ({
    type: AuthTypes.FORGOT_PASSWORD_ERROR,
    payload: { errorMsg },
});
export const ForgotPass = (email, onSuccess, onError) => async (dispatch) => {
    dispatch(ForgotPassBegin());

    try {
        await WebClient.post('/users/request-reset', { email });
        dispatch(ForgotPassSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && (
            error.response.status === 404
                || error.response.status === 400
        )) {
            errorType = 'NOT_FOUND';
        }

        dispatch(ForgotPassError(errorType));
        if (onError) onError(errorType);
    }
};

export const ResetPassBegin = () => ({
    type: AuthTypes.RESET_PASSWORD_BEGIN,
});
const ResetPassSuccess = () => ({
    type: AuthTypes.RESET_PASSWORD_SUCCESS,
});
const ResetPassError = (errorMsg) => ({
    type: AuthTypes.RESET_PASSWORD_ERROR,
    payload: { errorMsg },
});
export const ResetPass = (formValues, onSuccess, onError) => async (dispatch) => {
    dispatch(ResetPassBegin());

    const { email, resetToken, password } = formValues;

    try {
        await WebClient.post('/users/reset-password', {
            email,
            newPassword: password,
            resetToken,
        });
        dispatch(ResetPassSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && (
            error.response.error === 404
                || error.response.error === 400
        )) {
            errorType = 'NOT_FOUND';
        }

        dispatch(ResetPassError(errorType));
        if (onError) onError(errorType);
    }
};

export const SetPassBegin = () => ({
    type: AuthTypes.SET_PASSWORD_BEGIN,
});
const SetPassSuccess = () => ({
    type: AuthTypes.SET_PASSWORD_SUCCESS,
});
const SetPassError = (errorMsg) => ({
    type: AuthTypes.SET_PASSWORD_ERROR,
    payload: { errorMsg },
});
export const SetPass = (formValues, onSuccess, onError) => async (dispatch) => {
    dispatch(SetPassBegin());

    const { email, inviteToken, password } = formValues;

    try {
        await WebClient.post('/users/set-password', {
            email,
            newPassword: password,
            inviteToken,
        });
        dispatch(SetPassSuccess());
        dispatch(APISuccess('Please look for an email to confirm successful completion of the account setup.'));
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && (
            error.response.error === 404
                || error.response.error === 400
        )) {
            errorType = 'NOT_FOUND';
        }
        if (error.response.data.message === 'Invite has already been accepted') {
            if (onSuccess) onSuccess(); // redirects to index page
            dispatch(APIError("Our records indicate that an account has successfully been created for this email address.  Please login as usual.  If you need to reset your password, please click 'Forgot Password'"));
        } else {
            dispatch(APIError('There was an error. Please try again.'));
        }
        dispatch(SetPassError(errorType));
        if (onError) onError(errorType);
    }
};

const CheckTokenBegin = () => ({
    type: AuthTypes.CHECK_TOKEN_BEGIN,
});
const CheckTokenSuccess = () => ({
    type: AuthTypes.CHECK_TOKEN_SUCCESS,
});
const CheckTokenError = () => ({
    type: AuthTypes.CHECK_TOKEN_ERROR,
});

export const CheckToken = () => async (dispatch) => {
    dispatch(CheckTokenBegin());

    try {
        await WebClient.get('/users/authenticated');
        dispatch(CheckTokenSuccess());
    } catch (error) {
        dispatch(CheckTokenError());
        dispatch(Logout());
    }
};
