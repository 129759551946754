import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const getOrgsState = (state) => state.orgs;

export const getReferringOrganizations = createSelector(
    getOrgsState,
    (orgsState) => orgsState.referringOrganizations,
);

export const getPartnerOrganizations = createSelector(
    getOrgsState,
    (orgsState) => orgsState.partnerOrganizations,
);

export const getActivePartnerOrganizations = createSelector(
    getOrgsState,
    (orgsState) => orgsState.partnerOrganizations.filter((org) => org.deleted === false),
);

// create a "selector creator" that uses lodash.isequal instead of ===
const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    isEqual,
);

export const getPaginatedOrgs = createSelector(
    getOrgsState,
    (orgsState) => orgsState.paginatedOrgs.results,
);

const cleanPaginatedOrgsParams = createSelector(
    getOrgsState,
    (orgsState) => {
        const cleanedParams = { ...orgsState.paginatedOrgs.params };
        return cleanedParams;
    },
);

// uses lodash.isequal to compare state of the object
export const getPaginatedOrgsParams = createDeepEqualSelector(
    cleanPaginatedOrgsParams,
    (cleanedOrgsState) => cleanedOrgsState,
);

export const getTotalOrgsCount = createSelector(
    getOrgsState,
    (orgsState) => orgsState.paginatedOrgs.total,
);
