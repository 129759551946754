const removeEmpty = (obj) => {
    if (Array.isArray(obj)) return obj;
    return Object.entries(obj)
        .filter(([_, v]) => (v != null && v !== '')) // eslint-disable-line no-unused-vars
        .reduce((acc, [k, v]) => {
            if (v instanceof Date) return { ...acc, [k]: v };
            if (v === Object(v)) return { ...acc, [k]: removeEmpty(v) };
            return { ...acc, [k]: v };
        }, {});
};

export default removeEmpty;
