const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    SETUP_USER: true,
    GET_USER_BEGIN: true,
    GET_USER_SUCCESS: true,
    GET_USER_ERROR: true,
    UPDATE_USER_BEGIN: true,
    UPDATE_USER_SUCCESS: true,
    UPDATE_USER_ERROR: true,
    UPLOAD_USER_PHOTO_BEGIN: true,
    UPLOAD_USER_PHOTO_SUCCESS: true,
    UPLOAD_USER_PHOTO_ERROR: true,
    DISMISS_NOTIFICATION_BEGIN: true,
    DISMISS_NOTIFICATION_SUCCESS: true,
    DISMISS_NOTIFICATION_ERROR: true,
});
