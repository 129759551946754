import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';

import Colors from '../../../styles/colors';

const TertiaryStyledButton = withStyles((theme) => ({
    contained: {
        fontSize: '1.6rem',
        color: Colors.charcoal,
        backgroundColor: Colors.white,
        boxShadow: 'none',

        '& svg': {
            fill: Colors.midGray,
        },

        '&:hover': {
            backgroundColor: Colors.white,
            boxShadow: 'none',
            color: theme.palette.primary.main,
        },

        '&:focus': {
            boxShadow: `inset 0 0 2px 1px ${theme.palette.primary.main}`,
            backgroundColor: Colors.white,
        },

        '&:active, &[data-clicked=true]': {
            backgroundColor: `rgb(${Colors.charcoalRGB}, 0.1)`,
            boxShadow: 'none',
        },
    },
    disabled: {
        opacity: 0.3,
    },
}))(Button);

const TertiaryButton = ({ children, onClick, ...props }) => (
    <TertiaryStyledButton
        variant="contained"
        type=""
        onClick={onClick}
        disableRipple
        {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
        { children }
    </TertiaryStyledButton>
);

TertiaryButton.defaultProps = {
    children: null,
};

TertiaryButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func.isRequired,
};

export default TertiaryButton;
