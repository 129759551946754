import { createSelector } from 'reselect';

const getAppState = (state) => state.app;

export const getConfigLoaded = createSelector(
    getAppState,
    (appState) => appState.configLoaded,
);
export const getAPIStatus = createSelector(
    getAppState,
    (appState) => appState.apiStatus,
);

export const getAppConfig = createSelector(
    getAppState,
    (appState) => appState.config,
);

export const getAuthorizedFeatures = createSelector(
    getAppState,
    (appState) => appState.config.features,
);

export const getPrivacyPolicy = createSelector(
    getAppState,
    (appState) => appState.privacy,
);

export const getTerms = createSelector(
    getAppState,
    (appState) => appState.terms,
);

export const getApplicantTerms = createSelector(
    getAppState,
    (appState) => appState.applicantTerms,
);

export const getAppImages = createSelector(
    getAppState,
    (appState) => appState.config.images,
);

export const getAppConfigTheme = createSelector(
    getAppState,
    (appState) => ({
        typography: {
            ...appState.config.typography,
        },
        palette: appState.config.colors,
        userRoles: {
            ...appState.config.userRoles,
        },
        // if we don't override the components in this theme
        // then we can't get the colors to properly propogate
        components: {
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        backgroundColor: appState.config.colors.primary.main,
                        height: 3,
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        '&.MuiTab-root': {

                            color: appState.config.typography?.color,
                            '&.Mui-selected': {
                                color: appState.config.typography?.h1?.color,
                            },
                        },
                    },
                },
            },

            MuiFormControlLabel: { // checkboxes, radio buttons
                styleOverrides: {
                    root: {
                        '&.Radio-label': {
                            '&:hover': {
                                border: `1px solid ${appState.config.colors.primary.main}`,
                            },
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        '&$focused': {
                            border: `1px solid ${appState.config.colors.primary.main}`,
                        },
                        '&:hover': {
                            border: `1px solid ${appState.config.colors.primary.main}`,
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '&.MuiOutlinedInput-focused': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `1px solid ${appState.config.colors.primary.main} !important`,
                            },
                        },
                        '&:hover': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `1px solid ${appState.config.colors.primary.main} !important`,
                            },
                        },
                    },
                },
            },
            MuiRadio: {
                styleOverrides: {
                    root: {
                        '&.Mui-checked': {
                            color: appState.config.colors.primary.main,
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        color: appState.config.typography?.h1?.color,
                    },
                },
            },
        },
    }),
);

export const getContactEmail = createSelector(
    getAppState,
    (appState) => appState.config.contactInfo.email,
);

export const getCreateMatterFormQuestions = createSelector(
    getAppState,
    (appState) => appState.config.createMatterFormQuestions,
);
