const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    ADD_RESOURCE_BEGIN: true,
    ADD_RESOURCE_SUCCESS: true,
    ADD_RESOURCE_ERROR: true,
    EDIT_RESOURCE_BEGIN: true,
    EDIT_RESOURCE_SUCCESS: true,
    EDIT_RESOURCE_ERROR: true,
    DELETE_RESOURCE_BEGIN: true,
    DELETE_RESOURCE_SUCCESS: true,
    DELETE_RESOURCE_ERROR: true,
    UPDATE_PAGINATED_RESOURCES_PARAMS: true,
    GET_PAGINATED_RESOURCES_BEGIN: true,
    GET_PAGINATED_RESOURCES_SUCCESS: true,
    GET_PAGINATED_RESOURCES_ERROR: true,
    GET_RESOURCE_BEGIN: true,
    GET_RESOURCE_SUCCESS: true,
    GET_RESOURCE_ERROR: true,
    UPLOAD_BEGIN: true,
    UPLOAD_SUCCESS: true,
    UPLOAD_ERROR: true,
});
