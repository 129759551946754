import WebClient from '../utils/web-client';
import UserTypes from '../action-types/user';
import prepareContentType from '../utils/prepare-content-type';
import { APIError, APISuccess } from './app';

export const GetUserBegin = () => ({
    type: UserTypes.GET_USER_BEGIN,
});
const GetUserSuccess = (payload) => ({
    type: UserTypes.GET_USER_SUCCESS,
    payload,
});
const GetUserError = () => ({
    type: UserTypes.GET_USER_ERROR,
});
export const GetUser = () => async (dispatch) => {
    dispatch(GetUserBegin());

    try {
        const { data } = await WebClient.get('/user');
        dispatch(GetUserSuccess(data));
    } catch (error) {
        dispatch(GetUserError());
    }
};

export const UpdateUserBegin = () => ({
    type: UserTypes.UPDATE_USER_BEGIN,
});
const UpdateUserSuccess = () => ({
    type: UserTypes.UPDATE_USER_SUCCESS,
});
const UpdateUserError = () => ({
    type: UserTypes.UPDATE_USER_ERROR,
});
export const UpdateUser = (id, data) => async (dispatch) => {
    dispatch(UpdateUserBegin());

    try {
        await WebClient.patch(`/users/${id}`, data);
        dispatch(UpdateUserSuccess());
        dispatch(APISuccess('Save Successful.'));
        dispatch(GetUser());
    } catch (error) {
        dispatch(UpdateUserError());
        dispatch(APIError('Error saving profile.'));
    }
};

const UploadUserPhotoBegin = () => ({
    type: UserTypes.UPLOAD_USER_PHOTO_BEGIN,
});
const UploadUserPhotoSuccess = () => ({
    type: UserTypes.UPLOAD_USER_PHOTO_SUCCESS,
});
const UploadUserPhotoError = () => ({
    type: UserTypes.UPLOAD_USER_PHOTO_ERROR,
});

export const DismissNotificationBegin = () => ({
    type: UserTypes.DISMISS_NOTIFICATION_BEGIN,
});
const DismissNotificationSuccess = (payload) => ({
    type: UserTypes.DISMISS_NOTIFICATION_SUCCESS,
    payload,
});
const DismissNotificationError = () => ({
    type: UserTypes.DISMISS_NOTIFICATION_ERROR,
});
export const DismissNotification = (id) => async (dispatch) => {
    dispatch(DismissNotificationBegin());

    try {
        await WebClient.patch(`notifications/${id}/dismiss`);
        dispatch(GetUser());
        dispatch(DismissNotificationSuccess());
    } catch (error) {
        dispatch(DismissNotificationError());
    }
};

export const UploadUserPhoto = (file, onSuccess) => async (dispatch) => {
    dispatch(UploadUserPhotoBegin());

    const [formData, config] = prepareContentType('form-data', { photo: file });

    try {
        const uploadFile = await WebClient.post('/users/upload-photo', formData, config);
        dispatch(UploadUserPhotoSuccess());
        const payload = uploadFile.data.photoKey;
        if (onSuccess) onSuccess(payload);
    } catch (error) {
        dispatch(UploadUserPhotoError());
    }
};
