const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    API_ERROR: true,
    API_SUCCESS: true,
    API_RESET: true,
    GET_APP_CONFIG_BEGIN: true,
    GET_APP_CONFIG_SUCCESS: true,
    GET_APP_CONFIG_ERROR: true,
    SAVE_ADMIN_CONTENT_BEGIN: true,
    SAVE_ADMIN_CONTENT_SUCCESS: true,
    SAVE_ADMIN_CONTENT_ERROR: true,
});
