// Uploader Preprocessor
const form = (values) => {
    const formData = new FormData();

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in values) {
        let value = values[key];

        if (
            value && typeof value === 'object'
            && !(value instanceof Blob)
            && !(value instanceof Date)
            && !(Array.isArray(value))
        ) {
            value = JSON.stringify(value);
        }

        // handle forms different from other value types
        if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i += 1) {
                formData.append(key, value[i]);
            }
        } else {
            formData.append(key, value);
        }
    }

    return formData;
};

export default form;
