import WebClient from '../utils/web-client';
import OrgsTypes from '../action-types/orgs';
import { APIError, APISuccess, GetAppConfig } from './app';
import { GetMatterFull } from './matters';
import { getPaginatedOrgsParams } from '../selectors/orgs';
import form from '../utils/form-preprocessor';

const GetOrganizationsBegin = () => ({
    type: OrgsTypes.GET_ORGANIZATIONS_BEGIN,
});
const GetOrganizationsSuccess = (payload) => ({
    type: OrgsTypes.GET_ORGANIZATIONS_SUCCESS,
    payload,
});
const GetOrganizationsError = () => ({
    type: OrgsTypes.GET_ORGANIZATIONS_ERROR,
});

export const GetOrganizations = () => async (dispatch) => {
    dispatch(GetOrganizationsBegin());

    try {
        const { data } = await WebClient.get('/organizations');
        dispatch(GetOrganizationsSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetOrganizationsError());
    }
};

export const UpdatePaginatedOrgsParams = (payload) => ({
    type: OrgsTypes.UPDATE_PAGINATED_ORGS_PARAMS,
    payload,
});

const GetPaginatedOrgsBegin = () => ({
    type: OrgsTypes.GET_PAGINATED_ORGS_BEGIN,
});
const GetPaginatedOrgsSuccess = (payload) => ({
    type: OrgsTypes.GET_PAGINATED_ORGS_SUCCESS,
    payload,
});
const GetPaginatedOrgsError = () => ({
    type: OrgsTypes.GET_PAGINATED_ORGS_ERROR,
});

export const GetPaginatedOrgs = () => async (dispatch, getState) => {
    dispatch(GetPaginatedOrgsBegin());

    const paginatedOrgsParams = getPaginatedOrgsParams(getState());
    const payload = { ...paginatedOrgsParams };
    delete payload.page;
    delete payload.pageSize;
    delete payload.results;
    delete payload.total;

    try {
        const { data } = await WebClient.post(`/organizations/${paginatedOrgsParams.page}/${paginatedOrgsParams.pageSize}`, payload);
        dispatch(GetPaginatedOrgsSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetPaginatedOrgsError());
    }
};

const AddOrgBegin = () => ({
    type: OrgsTypes.ADD_ORG_BEGIN,
});
const AddOrgSuccess = () => ({
    type: OrgsTypes.ADD_ORG_SUCCESS,
});
const AddOrgError = () => ({
    type: OrgsTypes.ADD_ORG_ERROR,
});

export const AddOrg = (data, onSuccess) => async (dispatch) => {
    dispatch(AddOrgBegin());

    const payload = { ...data };

    const config = {
        headers: { 'content-type': 'multipart/form-data' },
    };
    try {
        await WebClient.post('/organizations', form(payload), config);
        dispatch(AddOrgSuccess());
        dispatch(APISuccess('Organization successfully added.'));
        dispatch(GetOrganizations()); // refresh available org list for when creating new matter
        dispatch(GetPaginatedOrgs()); // assumes we are on the site management / organizations page
        dispatch(GetAppConfig()); // keeps our "options" list current for creating new matters
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(AddOrgError());
        dispatch(APIError('Error adding organization.'));
    }
};

const EditOrgDetailsBegin = () => ({
    type: OrgsTypes.EDIT_ORG_DETAILS_BEGIN,
});
const EditOrgDetailsSuccess = () => ({
    type: OrgsTypes.EDIT_ORG_DETAILS_SUCCESS,
});
const EditOrgDetailsError = () => ({
    type: OrgsTypes.EDIT_ORG_DETAILS_ERROR,
});

export const EditOrgDetails = (orgId, data, onSuccess, matterId) => async (dispatch) => {
    dispatch(EditOrgDetailsBegin());

    const payload = { ...data };
    const config = {
        headers: { 'content-type': 'multipart/form-data' },
    };

    try {
        await WebClient.patch(`/organizations/${orgId}`, form(payload), config);
        dispatch(EditOrgDetailsSuccess());
        dispatch(GetAppConfig()); // keeps our "options" list current for creating new matters
        dispatch(GetOrganizations()); // refresh org list for when creating new matter
        if (matterId) dispatch(GetMatterFull(matterId)); // used to update a matter's presentation when editing a user inline
        else if (!matterId) dispatch(GetPaginatedOrgs()); // assumes we are on the site management / organizations page
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError());
        dispatch(EditOrgDetailsError());
    }
};

const DeleteOrgBegin = () => ({
    type: OrgsTypes.DELETE_ORG_BEGIN,
});
const DeleteOrgSuccess = () => ({
    type: OrgsTypes.DELETE_ORG_SUCCESS,
});
const DeleteOrgError = () => ({
    type: OrgsTypes.DELETE_ORG_ERROR,
});

export const DeleteOrg = (orgId, onSuccess, matterId) => async (dispatch) => {
    dispatch(DeleteOrgBegin());

    try {
        await WebClient.delete(`/organizations/${orgId}`);
        dispatch(DeleteOrgSuccess());
        dispatch(GetOrganizations()); // refresh org list for when creating new matter
        dispatch(GetAppConfig()); // keeps our "options" list current for creating new matters
        if (matterId) dispatch(GetMatterFull(matterId)); // used to update a matter's presentation when editing a user inline
        else if (!matterId) dispatch(GetPaginatedOrgs()); // assumes we are on the site management / organizations page
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError());
        dispatch(DeleteOrgError());
    }
};
