import ResourcesTypes from '../action-types/resources';

const internals = {
    initial: () => ({
        viewResource: null,
        viewResourceStatus: {
            error: false,
            errorMsg: '',
            completed: false,
        },
        paginatedResources: {
            page: 0,
            pageSize: 10,
            sortBy: 'title',
            sortOrder: 'asc',
            results: null,
            total: null,
        },
        paginatedResourcesStatus: {
            error: false,
            errorMsg: '',
            completed: false,
        },
    }),
};

const ResourceReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { type, payload } = action;

    switch (type) {
    case ResourcesTypes.GET_RESOURCE_BEGIN:
        return {
            ...state,
            viewResourceStatus: {
                error: false,
                errorMsg: '',
                completed: false,
            },
        };
    case ResourcesTypes.GET_RESOURCE_SUCCESS:
        return {
            ...state,
            viewResource: payload,
            viewResourceStatus: {
                error: false,
                errorMsg: '',
                completed: true,
            },
        };
    case ResourcesTypes.UPDATE_PAGINATED_RESOURCES_PARAMS: {
        return {
            ...state,
            paginatedResources: {
                ...state.paginatedResources,
                ...payload,
            },
        };
    }
    case ResourcesTypes.GET_PAGINATED_RESOURCES_BEGIN:
        return {
            ...state,
            paginatedResourcesStatus: {
                error: false,
                errorMsg: '',
                completed: false,
            },
        };
    case ResourcesTypes.GET_PAGINATED_RESOURCES_ERROR:
        return {
            ...state,
            paginatedResourcesStatus: {
                error: true,
                errorMsg: '',
                completed: true,
            },
        };
    case ResourcesTypes.GET_PAGINATED_RESOURCES_SUCCESS:
        return {
            ...state,
            paginatedResourcesStatus: {
                error: false,
                errorMsg: '',
                completed: true,
            },
            paginatedResources: {
                ...state.paginatedResources,
                results: payload.results,
                total: payload.total,
            },
        };
    case ResourcesTypes.ADD_RESOURCE_BEGIN:
        return {
            ...state,
            addResourceStatus: {
                error: false,
                completed: false,
            },
        };
    case ResourcesTypes.ADD_RESOURCE_SUCCESS:
        return {
            ...state,
            addResourceStatus: {
                error: false,
                completed: true,
            },
        };
    case ResourcesTypes.ADD_RESOURCE_ERROR:
        return {
            ...state,
            addResourceStatus: {
                error: true,
                completed: true,
            },
        };
    case ResourcesTypes.DELETE_RESOURCE_BEGIN:
        return {
            ...state,
            deleteResourceStatus: {
                error: false,
                completed: false,
            },
        };
    case ResourcesTypes.DELETE_RESOURCE_SUCCESS:
        return {
            ...state,
            deleteResourceStatus: {
                error: false,
                completed: true,
            },
        };
    case ResourcesTypes.DELETE_RESOURCE_ERROR:
        return {
            ...state,
            deleteResourceStatus: {
                error: true,
                completed: true,
            },
        };
    case ResourcesTypes.EDIT_RESOURCE_BEGIN:
        return {
            ...state,
            editResourceStatus: {
                error: false,
                completed: false,
            },
        };
    case ResourcesTypes.EDIT_RESOURCE_SUCCESS:
        return {
            ...state,
            editResourceStatus: {
                error: false,
                completed: true,
            },
        };
    case ResourcesTypes.EDIT_RESOURCE_ERROR:
        return {
            ...state,
            editResourceStatus: {
                error: true,
                completed: true,
            },
        };

    default:
        // do nothing
    }
    return state;
};

export default ResourceReducer;
