import AuthToken from './maintain-auth-token';
import Matters from './matters';
import App from './app';

const internals = {
    initializers: [AuthToken, Matters, App],
};

export default (store) => {
    internals.initializers.forEach((init) => init(store));
};
