import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const getFormsState = (state) => state.forms;

export const getViewForm = createSelector(
    getFormsState,
    (formState) => formState.viewForm,
);

// create a "selector creator" that uses lodash.isequal instead of ===
const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    isEqual,
);

export const getPaginatedForms = createSelector(
    getFormsState,
    (formsState) => formsState.paginatedForms.results,
);

// used to present 'getPaginatedMatterParams' with a cleaned state to compare
const cleanPaginatedFormsParams = createSelector(
    getFormsState,
    (formsState) => {
        const cleanedParams = { ...formsState.paginatedForms };
        delete cleanedParams.results;
        delete cleanedParams.total;
        return cleanedParams;
    },
);

// uses lodash.isequal to compare state of the object
export const getPaginatedFormsParams = createDeepEqualSelector(
    cleanPaginatedFormsParams,
    (cleanedFormsState) => cleanedFormsState,
);

export const getTotalFormsCount = createSelector(
    getFormsState,
    (formsState) => formsState.paginatedForms.total,
);
