import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

import TertiaryButton from '../buttons/tertiary-button';

import themer from '../../styles/material-theme';
import Styles from './styles';

import ErrorImg from './images/500-error@2x.png';

const ErrorBoundary = ({ classes }) => {
    const navigate = useNavigate();

    const handleBackToHomeClick = () => {
        navigate('/');
        // for some reason navigate('/') here only updates the URL but doesn't load the component
        navigate(-1);
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themer}>
                <main>
                    <div className="container">
                        <div className="row">
                            <div className="col text-center">
                                <Typography variant="h1">
                                    Oops!
                                </Typography>

                                <Typography variant="body1">
                                    Something went wrong on our end.
                                </Typography>

                                <Typography variant="body1">
                                    Please try again.
                                </Typography>

                                <TertiaryButton
                                    className="mt-4 mb-3"
                                    onClick={handleBackToHomeClick}
                                >
                                    Back to Home
                                </TertiaryButton>

                                <div>
                                    <img src={ErrorImg} alt="" className={classes.icon} />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

ErrorBoundary.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(ErrorBoundary);
