import OrgsTypes from '../action-types/orgs';

const internals = {
    initial: () => ({
        partnerOrganizations: [],
        referringOrganizations: [],
        paginatedOrgs: {
            params: {
                page: 0,
                pageSize: 10,
                sortBy: 'createdAt',
                sortOrder: 'desc',
            },
            results: null,
            total: null,
        },
        paginatedOrgsStatus: {
            error: false,
            errorMsg: '',
            completed: false,
        },
        addOrgStatus: {
            error: false,
            completed: false,
        },
    }),
};

const OrgsReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { type, payload } = action;

    switch (type) {
    case OrgsTypes.GET_ORGANIZATIONS_SUCCESS:
        return {
            ...state,
            partnerOrganizations: payload.filter((org) => org.type === 'partner'),
            referringOrganizations: payload.filter((org) => org.type === 'referrer'),
        };
    case OrgsTypes.UPDATE_PAGINATED_ORGS_PARAMS: {
        const newState = { ...state };
        newState.paginatedOrgs.params = {
            ...state.paginatedOrgs.params,
            ...payload,
        };
        return newState;
    }
    case OrgsTypes.GET_PAGINATED_ORGS_BEGIN:
        return {
            ...state,
            paginatedOrgsStatus: {
                error: false,
                errorMsg: '',
                completed: false,
            },
        };
    case OrgsTypes.GET_PAGINATED_ORGS_ERROR:
        return {
            ...state,
            paginatedOrgsStatus: {
                error: true,
                errorMsg: '',
                completed: true,
            },
        };
    case OrgsTypes.GET_PAGINATED_ORGS_SUCCESS:
        return {
            ...state,
            paginatedOrgsStatus: {
                error: false,
                errorMsg: '',
                completed: true,
            },
            paginatedOrgs: {
                ...state.paginatedOrgs,
                results: payload.results,
                total: payload.total,
            },
        };
    case OrgsTypes.ADD_ORG_BEGIN:
        return {
            ...state,
            addOrgStatus: {
                error: false,
                completed: false,
            },
        };
    case OrgsTypes.ADD_ORG_SUCCESS:
        return {
            ...state,
            addOrgStatus: {
                error: false,
                completed: true,
            },
        };
    case OrgsTypes.ADD_ORG_ERROR:
        return {
            ...state,
            addOrgStatus: {
                error: true,
                completed: true,
            },
        };

    default:
        // do nothing
    }
    return state;
};

export default OrgsReducer;
