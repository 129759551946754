const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    VALIDATE_REQUEST_BEGIN: true,
    VALIDATE_REQUEST_SUCCESS: true,
    VALIDATE_REQUEST_ERROR: true,
    UPLOAD_DOC_BEGIN: true,
    UPLOAD_DOC_SUCCESS: true,
    UPLOAD_DOC_ERROR: true,
    DELETE_DOC_BEGIN: true,
    DELETE_DOC_SUCCESS: true,
    DELETE_DOC_ERROR: true,
    SUBMIT_DOCS_BEGIN: true,
    SUBMIT_DOCS_SUCCESS: true,
    SUBMIT_DOCS_ERROR: true,
});
